import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleInspectionMarkersVisible, selectIsVisible } from "../InspectionMarkers/InspectionMarkersReducer";

export const InspectionControls = () => {
  // assign dispatch
  const dispatch = useDispatch();
  const isVisible = useSelector(selectIsVisible);

  return (
    <div id="InspectionControls">
      <div className="IndividualControl">
        <Form.Check
          label={'Show Inspection Markers'}
          checked={isVisible}
          onChange={() => { dispatch(toggleInspectionMarkersVisible()) }}
        />
      </div>
    </div>
  );
}