import React from "react";
import config from "../../config.json";
import { useSelector } from "react-redux";
import { selectIsStartVisible, selectIsEndVisible } from "./PathPointReducer";
import PathPointsDraw from "./PathPointsDraw";

export const PathPoints = (props) => {
  const path = props.path;

  const points = path.path.points;

  const startVisible = useSelector(selectIsStartVisible);

  const endVisible = useSelector(selectIsEndVisible);

  const size = points.length;

  let startCord = [];
  let endCord = [];
  let index = 0;
  startCord.push({
    index: index++,
    x: parseFloat(points[0].x),
    y: parseFloat(points[0].y),
    z: parseFloat(points[0].z),
  });
  endCord.push({
    index: index++,
    x: parseFloat(points[size - 1].x),
    y: parseFloat(points[size - 1].y),
    z: parseFloat(points[size - 1].z),
  });

  return (
    <>
      {startCord.map((vector3) => {
        if (startVisible) {
          return (
            <PathPointsDraw
              point={vector3}
              color={config.STARTANDEND.START}
              scale={config.STARTANDEND.SCALE}
              key={index}
            />
          );
        } else {
          return (null);
        }
      })}
      ;
      {endCord.map((vector3) => {
        if (endVisible) {
          return (
            <PathPointsDraw
              point={vector3}
              color={config.STARTANDEND.END}
              scale={config.STARTANDEND.SCALE}
              key={index}
            />
          );
        } else {
          return (null);
        }
      })}
      ;
    </>
  );
};
