import React from "react";
import { useSelector } from "react-redux";
import { selectPaths } from "../Path/PathReducer";
import { OrientationMarker } from "./OrientationMarker";
import { selectNumberOfMarkers, selectIsVisible } from "./OrientationMarkersReducer";

export const OrientationMarkers = () => {

  // Get paths from state
  const pathList = useSelector(selectPaths);

  // Get number of markers per path from state
  const markers = useSelector(selectNumberOfMarkers);

  // Get orientation marker visibility from state
  const markersVisible = useSelector(selectIsVisible);

  return (
    <>
      {pathList.map((path) => {
        return (path.show && markersVisible) ? (
          <React.Fragment key={path.index} >
            <OrientationMarker
              path={path}
              number_of_markers={markers}
              color={path.color}
            />
          </React.Fragment>
        ) : null;
      })}
    </>
  );
}
