import { createSlice } from "@reduxjs/toolkit";

// State slice to manage the number of visible orientation markers
export const ToolTipSlice = createSlice({
  name: "tooltip",
  initialState: {
    numberOfMarkers: 10,
    markersVisible: true,
    selectedData: {
      marker: "",
      timestamp: 0,
      x: 0,
      y: 0,
      z: 0,
      pitch: 0,
      yaw: 0,
      roll: 0,
      time: 0,
      velX: 0,
      velY: 0,
      velZ: 0,
    },

    // selectedData: {marker: "", timestamp: 0, x: 0, y:0, z: 0, pitch: 0, yaw:0, roll:0, time: 0, velX: 0, velY: 0, velZ: 0}
  },
  reducers: {
    // Reducer to show orientation marker data
    showOrientationReducerInfo: (state, action) => {
      if (action.payload.x !== state.selectedData.x) {
        state.selectedData.marker = "orientation";
        state.selectedData.timestamp = action.payload.timestamp;
        state.selectedData.x = action.payload.vector.x;
        state.selectedData.y = action.payload.vector.y;
        state.selectedData.z = action.payload.vector.z;
        state.selectedData.pitch = action.payload.vector.pitchDeg;
        state.selectedData.yaw = action.payload.vector.yawDeg;
        state.selectedData.roll = action.payload.vector.rollDeg;
        state.selectedData.time = action.payload.vector.time;
        state.selectedData.velX = action.payload.vector.velX;
        state.selectedData.velY = action.payload.vector.velY;
        state.selectedData.velZ = action.payload.vector.velZ;
      }
    },
    // Reducer to show Inspection marker data
    showInspectionReducerInfo: (state, action) => {
      if (action.payload.x !== state.selectedData.x) {
        state.selectedData.marker = "inspection";
        state.selectedData.x = action.payload.vector.x;
        state.selectedData.y = action.payload.vector.y;
        state.selectedData.z = action.payload.vector.z;
        state.selectedData.timestamp = 0;
        state.selectedData.pitch = 0;
        state.selectedData.yaw = 0;
        state.selectedData.roll = 0;
        state.selectedData.time = 0;
        state.selectedData.velX = 0;
        state.selectedData.velY = 0;
        state.selectedData.velZ = 0;
      }
    },
    // Reducer to show Start/End marker data
    showPathPointReducerInfo: (state, action) => {
      if (action.payload.x !== state.selectedData.x) {
        state.selectedData.marker = "start/end";
        state.selectedData.x = action.payload.vector.x;
        state.selectedData.y = action.payload.vector.y;
        state.selectedData.z = action.payload.vector.z;
        state.selectedData.timestamp = 0;
        state.selectedData.pitch = 0;
        state.selectedData.yaw = 0;
        state.selectedData.roll = 0;
        state.selectedData.time = 0;
        state.selectedData.velX = 0;
        state.selectedData.velY = 0;
        state.selectedData.velZ = 0;
      }
    },
    resetReducerInfo: (state) => {
      state.selectedData.marker = "";
      state.selectedData.x = 0;
      state.selectedData.y = 0;
      state.selectedData.z = 0;
      state.selectedData.pitch = 0;
      state.selectedData.yaw = 0;
      state.selectedData.roll = 0;
      state.selectedData.time = 0;
      state.selectedData.velX = 0;
      state.selectedData.velY = 0;
      state.selectedData.velZ = 0;
    },
  },
});

export const {
  showOrientationReducerInfo,
  showInspectionReducerInfo,
  showPathPointReducerInfo,
  resetReducerInfo,
} = ToolTipSlice.actions;
export const selectToolTipData = (state) => state.tooltip.selectedData;
export default ToolTipSlice.reducer;
