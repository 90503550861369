import { createSlice } from "@reduxjs/toolkit";
import config from "../../config.json";
import loadPaths from "../../utils/loadPaths";

/* 
    --- DELETE BEFORE COMMIT: ---
    Getting an error about non-serializable items being stored in state
    this is because the path element of the object stored in the paths
    array is of type 'catmull room curve 3', I think nesting an object
    inside of another object is the reason for this error. The error is 
    non-critical but if we have issues with the persistance of our store,
    this is the first place we should look.
*/

// PathDataArray moved to PathReducer
export const pathDataArray = [
  {
    file: "/assets/paths/Subject_1_Run_1.json",
    color: config.COLORS.TEAL.PRIMARY,
    index: 0,
  },
  {
    file: "/assets/paths/Subject_1_Run_2.json",
    color: config.COLORS.TEAL.SECONDARY,
    index: 1,
  },
  {
    file: "/assets/paths/Subject_2_Run_1.json",
    color: config.COLORS.GREEN.PRIMARY,
    index: 2,
  },
  {
    file: "/assets/paths/Subject_2_Run_2.json",
    color: config.COLORS.GREEN.SECONDARY,
    index: 3,
  },
  {
    file: "/assets/paths/Subject_3_Run_1.json",
    color: config.COLORS.ORANGE.PRIMARY,
    index: 4,
  },
  {
    file: "/assets/paths/Subject_3_Run_2.json",
    color: config.COLORS.ORANGE.SECONDARY,
    index: 5,
  },
  {
    file: "/assets/paths/Subject_4_Run_1.json",
    color: config.COLORS.PINK.PRIMARY,
    index: 6,
  },
  {
    file: "/assets/paths/Subject_4_Run_2.json",
    color: config.COLORS.PINK.SECONDARY,
    index: 7,
  },
];

export const PathSlice = createSlice({
  name: "path",
  initialState: {
    paths: [{}],
    visiblePaths: [
      { show: true },
      { show: false },
      { show: true },
      { show: false },
      { show: true },
      { show: false },
      { show: true },
      { show: false },
    ],
  },
  reducers: {
    // Reducer to load paths
    loadPath: (state) => {
      // Load and store each path in state
      // eslint-disable-next-line
      pathDataArray.map((pathData) => {
        // loadPaths does the loading and compressing previously done in FlightPath
        const curve = loadPaths(pathData.file);

        // Add curve to paths array and set index in visiblePaths to true
        state.paths[pathData.index] = {
          path: curve.curve,
          orientation: curve.orientationValues,
          difficult: curve.difficultValues,
          index: pathData.index,
          color: pathData.color,
          show: state.visiblePaths[pathData.index].show,
        };
      });
    },
    // Reducer to flip the boolean value at an index in visiblePaths
    changeVisible: (state, action) => {
      const index = action.payload.index;

      state.paths[index].show = !state.visiblePaths[index].show;
      state.visiblePaths[index].show = !state.visiblePaths[index].show;
    },
  },
});

export const { loadPath, changeVisible } = PathSlice.actions;
export const selectVisiblePaths = (state) => state.path.visiblePaths;
export const selectPaths = (state) => state.path.paths;
export const selectCurrentColors = (state) => state.path.currentColor;
export default PathSlice.reducer;
