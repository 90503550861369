import InspectionMarkerDraw from "./InspectionMarkerDraw";
import { useSelector } from "react-redux";
import { selectIsVisible } from "./InspectionMarkersReducer"

export const InspectionMarkers = () => {
  let InspectionMarkersFile = "/assets/inspection markers/Marker_Data.json";

  // Request the data
  let request = new XMLHttpRequest();
  request.open("GET", process.env.PUBLIC_URL + InspectionMarkersFile, false);
  request.send(null);
  let InspectionMarkersFileObject = JSON.parse(request.responseText);

  // Parse the data into floats
  let inspectionPoints = [];
  let index = 0;
  InspectionMarkersFileObject.forEach(element => {
    inspectionPoints.push({
      index: index++,
      x: parseFloat(element.x),
      y: parseFloat(element.y),
      z: parseFloat(element.z),
    });
  });

  // Get inspection marker visibility from state
  const markersVisible = useSelector(selectIsVisible)

  // Returns a box mesh (Rendered to the screen)
  return (markersVisible) ? (
    <>
      {inspectionPoints.map((vector3) => {
        return (
          <InspectionMarkerDraw
            marker={vector3}
            key={vector3.index}
          />
        );
      })};
    </>
  ) : null;
};