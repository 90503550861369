import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleOrientationMarkersVisible,
  selectIsVisible,
  selectNumberOfMarkers,
  changeNumberOfOrientationMarkers,
} from "../OrientationMarkers/OrientationMarkersReducer";

export const OrientationControls = () => {
  // Get Number of markers
  const markers = useSelector(selectNumberOfMarkers);

  // assign dispatch
  const dispatch = useDispatch();
  const isVisible = useSelector(selectIsVisible);

  return (
    <div id="OrientationControls">
      <header>Drone Markers</header>
      <div className="IndividualControl">
        <Form.Check
          label={"Show Drone Orientation Markers"}
          checked={isVisible}
          onChange={() => {
            dispatch(toggleOrientationMarkersVisible());
          }}
        />
      </div>

      <div className="IndividualControl">
        <label>Orientation Markers per Path</label>
        <div className="ticks">
          <span className="tick"></span>
          <span className="tick"></span>
          <span className="tick"></span>
          <span className="tick"></span>
          <span className="tick"></span>
        </div>
        <Form.Range
          value={markers}
          onChange={(e) => {
            dispatch(changeNumberOfOrientationMarkers(e.target.value));
          }}
        />
      </div>
    </div>
  );
};
