import React from "react";
import { Html } from "@react-three/drei";
import { Spinner } from "react-bootstrap";
import "./Loader.css";

export const Loader = () => {
  return (
    <Html center>
      <Spinner animation="border" role="status" className="Loader" />
    </Html>
  );
};
