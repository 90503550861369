import React from "react";
import FlightCurve from "./FlightCurve";
import config from "../../config.json";

export default function FlightPath(props) {

  // Returns a <FlightCurve /> (Rendered to the screen)
  return (
    <group
      position={config.TUBE.POSITION}
      scale={config.TUBE.SCALE}
    >
      <FlightCurve
        curve={props.pathData.path}
        color={props.pathData.color}
      />
    </group>
  );
};

FlightPath.defaultProps = {
  pathData: undefined,
};
