import React, { useState } from "react";
import { Modal, Button, Row, Col, Card } from "react-bootstrap";

export const AboutModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="ButtonWrapper">
        <Button
          variant=""
          onClick={handleShow}
          className="ButtonElement"
        >
          About The Project
        </Button>
      </div>

      <Modal show={show} onHide={handleClose} size="xl" className="ModalWrapper">
        <Modal.Header closeButton>
          <Modal.Title>About The Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="RowElement">
            <Col>
              <Card className="CardElement">
                <Card.Body>
                  <Card.Subtitle className="CardTitle">Purpose Of The App:</Card.Subtitle>
                  <p className="ModalText">
                    Client: Ricardo Eiris, Assistant Professor, Construction
                    Management
                  </p>
                  <p> </p>
                  <p className="ModalText">
                    This app was designed to provide a web-based method of
                    visualizing drone flights used in interior buildings
                    inspections. This visualization will enable drone pilots to
                    better understand the flight paths of professional pilots
                    who have already conducted simulated inspections within the
                    particular space.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card className="CardElement">
                <Card.Body>
                  <Card.Subtitle className="CardTitle">Technologies Used:</Card.Subtitle>
                  <Row>
                    <li>Visual Studio Code</li>
                    <p> </p>
                  </Row>

                  <Row>
                    <Col>
                      <p className="ModalText">- Languages -</p>
                      <li>JavaScript</li>
                      <li>Html</li>
                      <li>CSS</li>
                    </Col>

                    <Col>
                      <p className="ModalText">- Libraries -</p>
                      <li>React</li>
                      <li>Redux</li>
                      <li>ThreeJS</li>
                      <li>react-three drei</li>
                      <li>react-three fiber</li>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="RowElement">
            <Col>
              <Card className="CardElement">
                <Card.Body>
                  <Card.Subtitle className="CardTitle">Senior Design Team</Card.Subtitle>
                  <li className="ModalText">
                    Robert Pastel, Associate Professor, Product Owner/Manager
                  </li>
                  <li className="ModalText">
                    Audrey Schmitt, Student, Scrum Master/Developer
                  </li>
                  <li className="ModalText">
                    Joshua Reynolds, Student, Scrum Master/Developer
                  </li>
                  <li className="ModalText">
                    Thomas Carey, Student, Developer
                  </li>
                  <li className="ModalText">
                    Patrick Seidel, Student, Developer
                  </li>
                  <li className="ModalText">
                    Parker Kirwin, Student, Developer
                  </li>
                  <li className="ModalText">
                    Austin Gennrich, Student, Developer
                  </li>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card className="CardElement">
                <Card.Body>
                  <Card.Subtitle className="CardTitle">UI Initial Design Team:</Card.Subtitle>
                  <li className="ModalText">
                    Robert Pastel, Associate Professor, Product Owner/Manager
                  </li>
                  <li className="ModalText">
                    Tom Berg, Student, Developer
                  </li>
                  <li className="ModalText">
                    Ian Lawrie, Student, Developer
                  </li>
                  <li className="ModalText">
                    August Miller, Student, Developer
                  </li>
                  <li className="ModalText">
                    Eva Muller, Student, Developer
                  </li>
                  <li className="ModalText">
                    Abby Myers, Student, Developer
                  </li>
                  <li className="ModalText">
                    Charles Vidro, Student, Developer
                  </li>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
