import { createSlice } from "@reduxjs/toolkit";

// State slice to manage the number of visible orientation markers
export const OrientationSlice = createSlice({
  name: "orientation",
  initialState: {
    numberOfMarkers: 10,
    markersVisible: true,
    selectedData: {
      timestamp: 0,
      x: 0,
      y: 0,
      z: 0,
      pitch: 0,
      yaw: 0,
      roll: 0,
      time: 0,
      velX: 0,
      velY: 0,
      velZ: 0,
    },
  },
  reducers: {
    // Reducer to change the number of orientation markers on each path
    changeNumberOfOrientationMarkers: (state, action) => {
      // console.log("slider value: " + action.payload);
      state.numberOfMarkers = action.payload;
    },

    // Reducer to change visibility of markers
    toggleOrientationMarkersVisible: (state) => {
      state.markersVisible = !state.markersVisible;
    },
  },
});

export const {
  changeNumberOfOrientationMarkers,
  toggleOrientationMarkersVisible,
  showReducerInfo,
  resetReducerInfo,
} = OrientationSlice.actions;
export const selectNumberOfMarkers = (state) =>
  state.orientation.numberOfMarkers;
export const selectIsVisible = (state) => state.orientation.markersVisible;
export const selectData = (state) => state.orientation.selectedData;
export default OrientationSlice.reducer;
