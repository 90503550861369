import { createSlice } from "@reduxjs/toolkit";

// State slice to manage the number of visible orientation markers
export const InspectionSlice = createSlice({
  name: "inspection",
  initialState: {
    markersVisible: true,
  },
  reducers: {
    // Reducer to change visibility of markers
    toggleInspectionMarkersVisible: (state) => {
      state.markersVisible = !state.markersVisible;
    },
  },
});

export const {
  toggleInspectionMarkersVisible,
  showReducerInfo,
  resetReducerInfo,
} = InspectionSlice.actions;
export const selectIsVisible = (state) => state.inspection.markersVisible;
export default InspectionSlice.reducer;
