import "./ToolTip.css";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { selectToolTipData } from "./ToolTipReducer";

export const ToolTip = () => {
  var tooltipData = useSelector(selectToolTipData);

  if (tooltipData.marker === "inspection") {
    return (
      <>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Inspection Marker:</p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">
              Location: {"{"}
              {tooltipData.x.toFixed(2)}, {tooltipData.z.toFixed(2)}
              {"}"}{" "}
            </p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Height: {tooltipData.y.toFixed(2)}</p>
          </Col>
        </Row>
      </>
    );
  } else if (tooltipData.marker === "orientation") {
    return (
      <>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Orientation Marker: </p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">
              Location: {"{"}
              {tooltipData.x.toFixed(2)}, {tooltipData.z.toFixed(2)}
              {"}"}{" "}
            </p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Height: {tooltipData.y.toFixed(2)}</p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">
              Speed: {"{"}
              {tooltipData.velX.toFixed(2)}, {tooltipData.velY.toFixed(2)},{" "}
              {tooltipData.velZ.toFixed(2)}
              {"}"}{" "}
            </p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Yaw: {tooltipData.yaw.toFixed(2)}</p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Time: {tooltipData.time.toFixed(0)} </p>
          </Col>
        </Row>
      </>
    );
  } else if (tooltipData.marker === "start/end" && tooltipData.x === 0 && tooltipData.z === 0) {
    return (
      <>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Start Marker: </p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">
              Location: {"{"}
              {tooltipData.x.toFixed(2)}, {tooltipData.z.toFixed(2)}
              {"}"}{" "}
            </p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Height: {tooltipData.y.toFixed(2)}</p>
          </Col>
        </Row>
      </>
    );
  } else if (tooltipData.marker === "start/end") {
    return (
      <>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">End Marker: </p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">
              Location: {"{"}
              {tooltipData.x.toFixed(2)}, {tooltipData.z.toFixed(2)}
              {"}"}{" "}
            </p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Height: {tooltipData.y.toFixed(2)}</p>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Hover over an:</p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Inspection Marker,</p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">Orientation Marker</p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">or Start/End Marker</p>
          </Col>
        </Row>
        <Row id="Row">
          <Col id="Col">
            <p id="orientationData">to view data. </p>
          </Col>
        </Row>
      </>
    );
  }
};
