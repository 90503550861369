import React from "react";
import * as THREE from "three";

import OrientationMarkerDraw from "./OrientationMarkerDraw";


export const OrientationMarker = (props) => {

  // function to add marker to render list
  function renderMarker(i) {
    let pitch = toRadians(parseFloat(orientationList[i].pitch));
    let yaw = toRadians(parseFloat(orientationList[i].yaw));
    let roll = toRadians(parseFloat(orientationList[i].roll));

    // Seperate pitch yaw and roll to store degree data
    let pitchDeg = parseFloat(orientationList[i].pitch);
    let yawDeg = parseFloat(orientationList[i].yaw);
    let rollDeg = parseFloat(orientationList[i].roll);


    const orientation = new THREE.Euler(pitch + (Math.PI) / 2, roll, (2 * Math.PI) - yaw, 'XYZ');

    markerCoord.push({
      x: parseFloat(pointsList[i].x),
      y: parseFloat(pointsList[i].y),
      z: parseFloat(pointsList[i].z),
      pitchDeg: pitchDeg,
      yawDeg: yawDeg,
      rollDeg: rollDeg,
      orientation: orientation,
      time: parseFloat(orientationList[i].time),
      velX: parseFloat(orientationList[i].velX),
      velY: parseFloat(orientationList[i].velY),
      velZ: parseFloat(orientationList[i].velZ),
      color: props.color,
      hovered: false,
    })
  }

  // Function to convert degrees to radians
  function toRadians(degree) {
    let angle = parseFloat(degree);
    return (angle * (Math.PI / 180));
  }


  // Get number points and path
  const path = props.path;


  let markerCoord = [];


  const pointsList = path.path.points;
  const orientationList = path.orientation;

  let percentage = (parseFloat(props.number_of_markers) / 100);

  // boolean to track if percentage is over 50%
  const isOverHalf = (percentage > .5);

  // If percentage is over half, percentage now represents percent hidden
  if (isOverHalf) {
    percentage = 1 - percentage;
  }

  const points = percentage * pointsList.length;

  // counter to track rendering and hiding
  var j = 1;

  // Ratio of data points to direction arrows
  const ratio = Math.floor(pointsList.length / points);

  for (let i = 0; i < pointsList.length; i++) {
    // Percentage is less than half, hide ratio times, render once
    if (!isOverHalf) {
      if (j === ratio) {
        // reset tracker
        j = 0;
        // Render marker
        renderMarker(i);
      }
    } else {
      // Percentage is over half, render ratio times, hide once
      if (j < ratio) {
        renderMarker(i);
      } else {
        // Reset tracker
        j = 0;
      }
    }
    // Increment tracker
    j++;
  }

  // OnPointerOver() is a thing I can use, maybe change color of hovered 
  // marker.
  let index = 0;
  return (
    <>
      {markerCoord.map((vector3) => {
        return (
          <OrientationMarkerDraw
            marker={vector3}
            color={props.color}
            key={index++}
          />
        );
      })};
    </>
  );
}

OrientationMarker.defaultProps = {
  path: undefined,
  color: undefined,
}

