import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import config from "../../config.json";

import {
  showPathPointReducerInfo,
  resetReducerInfo,
} from "../ToolTip/ToolTipReducer";

export default function PathPointsDraw(props) {
  const vector3 = props.point;
  const color = props.color;
  const scale = props.scale;
  const hoveredColor = config.COLORS.HOVERED;
  const dispatch = useDispatch();

  const [isHovered, setIsHovered] = useState(false);

  function handleHoverPointerOn(vector3) {
    // console.log("vector3 data", vector3);
    dispatch(showPathPointReducerInfo({ timestamp: 0, vector: vector3 }));
    setIsHovered(true);
  }

  function handleHoverPointerOff() {
    dispatch(resetReducerInfo());
    setIsHovered(false);
  }

  return (
    <React.Fragment key={vector3.index}>
      <mesh
        position={[vector3.x, vector3.y, vector3.z]}
        scale={scale}
        onPointerEnter={() => {
          handleHoverPointerOn(vector3);
        }}
        onPointerOut={() => handleHoverPointerOff()}
      >
        <sphereGeometry />
        <meshStandardMaterial color={isHovered ? hoveredColor : color} />
      </mesh>
    </React.Fragment>
  );
}
