import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedCameraState, setTransform, toggleCamera } from "../Camera/CameraReducer"
import { globalCameraTransform } from "../../App";

export const CameraControls = () => {
  const dispatch = useDispatch();
  const activeCamera = useSelector(selectedCameraState);


  return (
    <div>
      <header>Camera Toggle</header>
      <div className="IndividualControl">
        <div>
          <input
            type="radio"
            id="perspectiveCamera"
            name="cameraChoiceGroup"
            value="perspectiveCamera"
            checked={activeCamera[0].show}
            onChange={() => { dispatch(toggleCamera(0)); dispatch(setTransform(globalCameraTransform)); }}
          />
          <label className="RadioLabel">Perspective Camera</label>
        </div>

        <div>
          <input
            type="radio"
            id="OrthographicCamera"
            name="cameraChoiceGroup"
            value="OrthographicCamera"
            checked={activeCamera[1].show}
            onChange={() => { dispatch(toggleCamera(1)); dispatch(setTransform(globalCameraTransform)); }}
          />
          <label className="RadioLabel">Orthographic Camera</label>
        </div>
      </div>
    </div>
  );
};
