import React from "react";
import FlightPath from "./FlightPath"
import { useSelector } from "react-redux";
import { selectPaths } from "./PathReducer";

// Returns a <FlightPath /> for each run
export const FlightPaths = () => {

  const paths = useSelector(selectPaths);

  return (
    <>
      {paths.map((path) => {
        return (path.show) ? (
          <React.Fragment key={path.index}>
            <FlightPath
              pathData={path}
            />
          </React.Fragment>
        ) : null;
      })}
    </>
  );
};
