import React from 'react'
import { Tube } from "@react-three/drei";
import * as THREE from "three";
import config from "../../config.json"

export default function FlightCurve(props) {

  const material = new THREE.MeshStandardMaterial({
    flatShading: config.TUBE.MATERIAL.FLATSHADING,
    metalness: config.TUBE.MATERIAL.METALNESS,
    envMapIntensity: config.TUBE.MATERIAL.ENVMAPINTENSITY,
    opacity: config.TUBE.MATERIAL.OPACITY,
    transparent: config.TUBE.MATERIAL.TRANSPARENT,
    depthWrite: config.TUBE.MATERIAL.DEPTHWRITE,
    wireframe: config.TUBE.MATERIAL.WIREFRAME,
    color: props.color
  });

  if (props.curve === undefined) {
    return null;
  } else {

    return (
      <Tube
        args={[
          props.curve, // Curve created in FlightPath.jsx
          props.curve.points.length * props.resolution, // Tubular segments
          props.radius,
          props.radialSegments,
          props.closed
        ]}
        rotation={[
          (props.rotateX * Math.PI) / 180.0,
          (props.rotateY * Math.PI) / 180.0,
          (props.rotateZ * Math.PI) / 180.0,
        ]}
        material={material}
      />
    )
  }
}

FlightCurve.defaultProps = {
  color: config.TUBE.COLOR,
  radius: config.TUBE.RADIUS,
  resolution: config.MAX_POINTS / 2,
  curve: undefined,
  radialSegments: config.TUBE.RADIALSEGMENTS,
  closed: config.TUBE.CLOSED,
  rotateX: config.TUBE.ROTATION.YAW,
  rotateY: config.TUBE.ROTATION.YAW,
  rotateZ: config.TUBE.ROTATION.ROLL
};
