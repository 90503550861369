import { createSlice } from "@reduxjs/toolkit";

export const CameraSlice = createSlice({
  name: "camera",
  initialState: {
    position: [-6.8, 4.3, 21.4],
    rotation: [-0.15, -0.05, -0.01],
    selectedCamera: [{ show: true }, { show: false }],
  },
  reducers: {
    // Reducer to flip the boolean value at an index
    toggleCamera: (state, action) => {
      const index = action.payload;
      for (let i = 0; i < state.selectedCamera.length; i++) {
        if (i === index) {
          state.selectedCamera[index].show = true;
        } else {
          state.selectedCamera[i].show = false;
        }
      }
    },

    setTransform: (state, action) => {
      state.position = action.payload.position;
      state.rotation = action.payload.rotation;
    },
  },
});

export const { toggleCamera, setTransform } = CameraSlice.actions;
export const selectedCameraState = (state) => state.camera.selectedCamera;
export const selectedCameraPosition = (state) => state.camera.position;
export const selectedCameraRotation = (state) => state.camera.rotation;
export default CameraSlice.reducer;
