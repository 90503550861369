import "./Legend.css";
import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Legend = () => {
  return (
    <>
      <Row id="Row">
        <Col id="Col">
          <p id="TitleText">Symbol Legend:</p>
        </Col>
      </Row>

      <Row id="Row">
        <Col id="DroneFlightPathCol">
          Drone Flight Paths
        </Col>
        <Col id="DroneDataCol">
          Drone Data
        </Col>
      </Row>

      <Row id="Row">
        <Col id="DroneFlightPathCol">
          <div id="DroneFlightPrimaryColorTeal"></div>
          <div id="DroneFlightSecondaryColorTeal"></div>
        </Col>

        <Col id="DroneDataCol">
          <Row id="Row">
            <Col id="Col">
              <Row id="Row">
                <Col id="Col">
                  <div id="DronePilotPrimaryColorTeal"></div>
                  <div id="DronePilotSecondaryColorTeal"></div>
                </Col>
                <Col id="Col">
                  <p id="DroneDataTextFlight">Flight 1</p>
                  <p id="DroneDataTextFlight">Flight 2</p>
                </Col>
              </Row>
            </Col>

            <Col id="Col">
              <p id="DroneDataTextPilot">Pilot 1</p>
            </Col>
          </Row>
        </Col>
      </Row>


      <Row id="Row">
        <Col id="DroneFlightPathCol">
          <div id="DroneFlightPrimaryColorGreen"></div>
          <div id="DroneFlightSecondaryColorGreen"></div>
        </Col>

        <Col id="DroneDataCol">
          <Row id="Row">
            <Col id="Col">
              <Row id="Row">
                <Col id="Col">
                  <div id="DronePilotPrimaryColorGreen"></div>
                  <div id="DronePilotSecondaryColorGreen"></div>
                </Col>
                <Col id="Col">
                  <p id="DroneDataTextFlight">Flight 1</p>
                  <p id="DroneDataTextFlight">Flight 2</p>
                </Col>
              </Row>
            </Col>

            <Col id="Col">
              <p id="DroneDataTextPilot">Pilot 2</p>
            </Col>
          </Row>
        </Col>
      </Row>


      <Row id="Row">
        <Col id="DroneFlightPathCol">
          <div id="DroneFlightPrimaryColorOrange"></div>
          <div id="DroneFlightSecondaryColorOrange"></div>
        </Col>

        <Col id="DroneDataCol">
          <Row id="Row">
            <Col id="Col">
              <Row id="Row">
                <Col id="Col">
                  <div id="DronePilotPrimaryColorOrange"></div>
                  <div id="DronePilotSecondaryColorOrange"></div>
                </Col>
                <Col id="Col">
                  <p id="DroneDataTextFlight">Flight 1</p>
                  <p id="DroneDataTextFlight">Flight 2</p>
                </Col>
              </Row>
            </Col>

            <Col id="Col">
              <p id="DroneDataTextPilot">Pilot 3</p>
            </Col>
          </Row>
        </Col>
      </Row>


      <Row id="Row">
        <Col id="DroneFlightPathCol">
          <div id="DroneFlightPrimaryColorPink"></div>
          <div id="DroneFlightSecondaryColorPink"></div>
        </Col>

        <Col id="DroneDataCol">
          <Row id="Row">
            <Col id="Col">
              <Row id="Row">
                <Col id="Col">
                  <div id="DronePilotPrimaryColorPink"></div>
                  <div id="DronePilotSecondaryColorPink"></div>
                </Col>
                <Col id="Col">
                  <p id="DroneDataTextFlight">Flight 1</p>
                  <p id="DroneDataTextFlight">Flight 2</p>
                </Col>
              </Row>
            </Col>

            <Col id="Col">
              <p id="DroneDataTextPilot">Pilot 4</p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row id="Row">
        <Col id="PathStartWrapperCol">
          <Row id="Row">
            <Col id="PathStartImageCol">
              <div id="PathStart"></div>
            </Col>
            <Col id="PathStartTextCol">
              <p id="ExtraText">Path</p>
              <p id="ExtraText">Start</p>
            </Col>
          </Row>
        </Col>

        <Col id="PathEndWrapperCol">
          <Row id="Row">
            <Col id="PathEndImageCol">
              <div id="PathEnd"></div>
            </Col>
            <Col id="PathEndTextCol">
              <p id="ExtraText">Path</p>
              <p id="ExtraText">End</p>
            </Col>
          </Row>
        </Col>

        <Col id="InspectionMarkersWrapperCol">
          <Row id="Row">
            <Col id="InspectionMarkersImageCol">
              <div id="InspectionMarkers"></div>
            </Col>
            <Col id="InspectionMarkersTextCol">
              <p id="ExtraText">Inspection</p>
              <p id="ExtraText">Markers</p>
            </Col>
          </Row>
        </Col>

        <Col id="DifficultAreasWrapperCol">
          <Row id="Row">
            <Col id="DifficultAreasImageCol">
              <div id="DifficultAreas"></div>
            </Col>
            <Col id="DifficultAreasTextCol">
              <p id="ExtraText">Difficult</p>
              <p id="ExtraText">Areas</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
