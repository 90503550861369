import { configureStore } from "@reduxjs/toolkit";
import PathReducer from "../features/Path/PathReducer";
import InspectionMarkersReducer from "../features/InspectionMarkers/InspectionMarkersReducer";
import OrientationMarkersReducer from "../features/OrientationMarkers/OrientationMarkersReducer";
import RoomReducer from "../features/Room/RoomReducer";
import DifficultAreasReducer from "../features/DifficultAreas/DifficultAreasReducer";
import PathPointReducer from "../features/PathPoints/PathPointReducer";
import CameraReducer from "../features/Camera/CameraReducer";
import ToolTipReducer from "../features/ToolTip/ToolTipReducer";

export const store = configureStore({
  reducer: {
    path: PathReducer,
    inspection: InspectionMarkersReducer,
    orientation: OrientationMarkersReducer,
    room: RoomReducer,
    difficult: DifficultAreasReducer,
    startandend: PathPointReducer,
    camera: CameraReducer,
    tooltip: ToolTipReducer,
  },
});
