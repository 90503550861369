import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectVisibleRooms, toggleModel, toggleOff } from "../Room/RoomReducer";

export const RoomControls = () => {
  const dispatch = useDispatch();
  const visibleRooms = useSelector(selectVisibleRooms);


  return (
    <div>
      <header>Model Display</header>
      <div className="IndividualControl">
        <div>
          <input
            type="radio"
            id="roomModel"
            name="modelDisplayGroup"
            value="roomModel"
            checked={visibleRooms[0].show}
            onChange={() => { dispatch(toggleModel({ index: 0 })); }}
          />
          <label className="RadioLabel">Room Model</label>
        </div>

        <div>
          <input
            type="radio"
            id="roomModelWireframe"
            name="modelDisplayGroup"
            value="roomModelWireframe"
            checked={visibleRooms[1].show}
            onChange={() => { dispatch(toggleModel({ index: 1 })); }}
          />
          <label className="RadioLabel">Room Model Wireframe</label>
        </div>

        <div>
          <input
            type="radio"
            id="hideModels"
            name="modelDisplayGroup"
            value="hideModels"
            onChange={() => { dispatch(toggleOff()); }}
          />
          <label className="RadioLabel">Hide Models</label>
        </div>
      </div>
    </div>
  );
};
