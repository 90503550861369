import React from "react";
import config from "../../config.json";

export const DifficultArea = (props) => {

  const path = props.path;

  let difficultCord = [];

  const difficultList = path.difficult;

  let vMax = 0;

  difficultList.forEach(element => {
    if (parseFloat(element.speed) > vMax) {
      vMax = parseFloat(element.speed)
    }
  });
  // E.X. vMax = 2.3

  let threshold = parseFloat(props.percent_Of_Speed) / 100;

  let index = 0;
  difficultList.forEach(element => {
    if (parseFloat(element.speed) < vMax * parseFloat(threshold)) {
      difficultCord.push({
        index: index++,
        x: parseFloat(element.x),
        y: parseFloat(element.y),
        z: parseFloat(element.z),
      });
    }
  });

  return (
    <>
      {difficultCord.map((vector3) => {
        return (
          <mesh
            position={[vector3.x, vector3.y, vector3.z]}
            scale={config.DIFFICULTMARKERS.SCALE}
            key={vector3.index}
          >
            <sphereGeometry />
            <meshStandardMaterial
              color={config.DIFFICULTMARKERS.COLOR}
              transparent={true}
              opacity={config.DIFFICULTMARKERS.OPACITY}
            />
          </mesh>
        );
      })};
    </>
  );
}

