import React from "react";
import {
  selectedCameraPosition,
  selectedCameraRotation,
  selectedCameraState,
} from "./CameraReducer";
import { useSelector } from "react-redux";
import {
  PerspectiveCamera,
  OrthographicCamera,
} from "@react-three/drei";
import config from "../../config.json";

export const Camera = () => {
  const activeCamera = useSelector(selectedCameraState);
  const activePostion = useSelector(selectedCameraPosition);
  const activeRotation = useSelector(selectedCameraRotation);

  return (
    <>
      <PerspectiveCamera
        makeDefault={activeCamera[0].show}
        position={activePostion}
        rotation={activeRotation}
        zoom={config.CAMERA.PERSPECTIVE.ZOOM}
      />
      <OrthographicCamera
        makeDefault={activeCamera[1].show}
        position={activePostion}
        rotation={activeRotation}
        zoom={config.CAMERA.ORTHOGRAPHIC.ZOOM}
      />
    </>
  );
};
