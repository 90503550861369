import { createSlice } from "@reduxjs/toolkit";

// State slice to manage the number of visible orientation markers
export const DifficultSlice = createSlice({
  name: "difficult",
  initialState: {
    percentOfSpeed: 2,
    difficultAreasVisible: true,
  },
  reducers: {
    // Reducer to change the number of orientation markers on each path
    changePercentOfSpeed: (state, action) => {
      //console.log("slider value: " + action.payload);
      state.percentOfSpeed = action.payload;
    },

    // Reducer to change visibility of markers
    toggleDifficultAreasVisible: (state) => {
      state.difficultAreasVisible = !state.difficultAreasVisible;
    },
  },
});

export const { changePercentOfSpeed, toggleDifficultAreasVisible } =
  DifficultSlice.actions;

export const selectPercentOfSpeed = (state) => state.difficult.percentOfSpeed;
export const selectIsVisible = (state) => state.difficult.difficultAreasVisible;
export default DifficultSlice.reducer;
