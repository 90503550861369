import { Canvas } from "@react-three/fiber";
import { Room } from "./features/Room/Room";
import { FlightPaths } from "./features/Path/FlightPaths";
import { InspectionMarkers } from "./features/InspectionMarkers/InspectionMarkers";
import { OrbitControls } from "@react-three/drei";
import { Legend } from "./features/Legend/Legend";
import { OrientationMarkers } from "./features/OrientationMarkers/OrientationMarkers";
import "./App.css";
import config from "./config.json";
import { ControlPanel } from "./features/UserInterface/ControlPanel";
import React, { Suspense } from "react";
import { DifficultAreas } from "./features/DifficultAreas/DifficultAreas";
import { PathPoint } from "./features/PathPoints/PathPoint";
import { ToolTip } from "./features/ToolTip/ToolTip";
import { Camera } from "./features/Camera/Camera";
import { Loader } from "./features/loader/Loader";
import { Dispatcher } from "./features/loader/Dispatcher";
import { HelpModal } from "./features/HelpModal/HelpModal";

export var globalCameraTransform = [];

function App() {
  // App Title
  document.title = "3Drone";

  return (
    <div id="AppComponent">
      <div id="ControlPanel">
        <ControlPanel />
      </div>
      <Canvas>
        <Suspense fallback={<Loader />}>
          <Dispatcher />
          <Camera />
          <OrbitControls
            onEnd={(camera) => {
              globalCameraTransform = camera.target.object;
            }}
          />
          <ambientLight intensity={config.LIGHT.INTENSITY} />
          <directionalLight
            color={config.DIRECTIONALLIGHT.COLOR}
            position={config.DIRECTIONALLIGHT.POSITION.ONE}
          />
          <directionalLight
            color={config.DIRECTIONALLIGHT.COLOR}
            position={config.DIRECTIONALLIGHT.POSITION.TWO}
          />
          <InspectionMarkers />
          <OrientationMarkers />
          <DifficultAreas />
          <PathPoint />
          <Room />
          <FlightPaths />
        </Suspense>
      </Canvas>
      <div id="LegendDiv">
        <Legend />
      </div>
      <div id="ToolTipDiv">
        <ToolTip />
      </div>
      <div id="HelpModalDiv">
        <HelpModal />
      </div>
    </div>
  );
}

export default App;
