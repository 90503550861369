import { createSlice } from "@reduxjs/toolkit";
import config from "../../config.json";
import { LoadRooms } from "./Room";

export const roomDataArray = [
  {
    // this is the default room model
    roomPath: "/assets/models/room-model-drone-space.glb",
    rotateX: config.ROOM.ROTATION_STANDARD.PITCH,
    rotateY: config.ROOM.ROTATION_STANDARD.YAW,
    rotateZ: config.ROOM.ROTATION_STANDARD.ROLL,
    position: config.ROOM.POSITION_STANDARD,
    index: 0,
  },
  {
    // this is the low poly wireframe room model
    roomPath: "/assets/models/room-model-lowpoly-wireframe.glb",
    rotateX: config.ROOM.ROTATION_WIREFRAME.PITCH,
    rotateY: config.ROOM.ROTATION_WIREFRAME.YAW,
    rotateZ: config.ROOM.ROTATION_WIREFRAME.ROLL,
    position: config.ROOM.POSITION_WIREFRAME,
    index: 1,
  },
];

export const RoomSlice = createSlice({
  name: "room",
  initialState: {
    rooms: [{}],
    visibleRooms: [{ show: true }, { show: false }],
  },
  reducers: {
    // Reducer to load rooms
    loadRoom: (state) => {
      // Load and store each room in state
      // eslint-disable-next-line
      roomDataArray.map((roomData) => {
        const loadedPathsNode = LoadRooms(roomData);

        // Add preloaded room to room array and set index in visibleRooms to true
        state.rooms[roomData.index] = {
          rotateX: roomData.rotateX,
          rotateY: roomData.rotateY,
          rotateZ: roomData.rotateZ,
          position: roomData.position,
          index: roomData.index,
          node: loadedPathsNode,
          show: state.visibleRooms[roomData.index].show,
        };
      });
    },
    // Reducer to flip the boolean value at an index
    toggleModel: (state, action) => {
      const index = action.payload.index;

      for (let i = 0; i < state.rooms.length; i++) {
        if (i === index) {
          state.visibleRooms[index].show = true;
          state.rooms[index].show = true;
        } else {
          state.visibleRooms[i].show = false;
          state.rooms[i].show = false;
        }
      }
    },
    // Reducer to flip off all models
    toggleOff: (state) => {
      for (let i = 0; i < state.rooms.length; i++) {
        state.visibleRooms[i].show = false;
        state.rooms[i].show = false;
      }
    },
  },
});

export const { loadRoom, toggleModel, toggleOff } = RoomSlice.actions;
export const selectVisibleRooms = (state) => state.room.visibleRooms;
export const selectRooms = (state) => state.room.rooms;
export default RoomSlice.reducer;
