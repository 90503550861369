import React from "react";
import { useSelector } from "react-redux";
import { selectPaths } from "../Path/PathReducer";
import { PathPoints } from "./PathPoints";

export const PathPoint = () => {

  // Get paths from state
  const pathList = useSelector(selectPaths);

  return (
    <>
      {pathList.map((path) => {
        return (path.show) ? (
          <React.Fragment key={path.index} >
            <PathPoints
              path={path}
            />
          </React.Fragment>
        ) : null;
      })}
    </>
  );
}