import React from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import config from "../../config.json";
import { selectRooms } from "./RoomReducer";
import { useSelector } from "react-redux";

export const Room = () => {
  const rooms = useSelector(selectRooms);

  const material = new THREE.MeshStandardMaterial({
    flatShading: config.ROOM.MATERIAL.FLATSHADING,
    metalness: config.ROOM.MATERIAL.METALNESS,
    envMapIntensity: config.ROOM.MATERIAL.ENVMAPINTENSITY,
    opacity: config.ROOM.MATERIAL.OPACITY,
    transparent: config.ROOM.MATERIAL.TRANSPARENT,
    depthWrite: config.ROOM.MATERIAL.DEPTHWRITE,
    wireframe: config.ROOM.MATERIAL.WIREFRAME
  });

  // Returns a room that gets rendered to the screen
  let index = 0;
  return (
    <>
      {rooms.map((room) => {
        return (room.show) ? (
          <mesh dispose={null}
            rotation={[
              (room.rotateX * Math.PI) / 180.0,
              (room.rotateY * Math.PI) / 180.0,
              (room.rotateZ * Math.PI) / 180.0,
            ]}
            geometry={room.node.Point_Cloud_Model.geometry} // where Point_Cloud_Model is the name of the node in blender
            material={material}
            position={room.position}
            scale={config.ROOM.SCALE}
            key={index++}
          />
        ) : null;
      })}
    </>
  )
};

// function to preload all the rooms
export function LoadRooms(roomData) {
  useGLTF.preload(process.env.PUBLIC_URL + roomData.roomPath)
  const { nodes } = useGLTF(process.env.PUBLIC_URL + roomData.roomPath);

  return nodes;
};
