import React from "react";
import { useSelector } from "react-redux";
import { selectPaths } from "../Path/PathReducer";
import { DifficultArea } from "./DifficultArea";
import { selectPercentOfSpeed, selectIsVisible } from "./DifficultAreasReducer";

export const DifficultAreas = () => {

  const pathList = useSelector(selectPaths);

  const threshold = useSelector(selectPercentOfSpeed);

  const areasVisible = useSelector(selectIsVisible);

  return (
    <>
      {pathList.map((path) => {
        return (path.show && areasVisible) ? (
          <React.Fragment key={path.index} >
            <DifficultArea
              path={path}
              percent_Of_Speed={threshold}
            />
          </React.Fragment>
        ) : null;
      })}
    </>
  );
}
