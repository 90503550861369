import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import config from "../../config.json";

import { showOrientationReducerInfo, resetReducerInfo } from "../ToolTip/ToolTipReducer";

function OrientationMarkerDraw(props) {
  const vector3 = props.marker;
  const color = props.color;
  const hoveredColor = config.COLORS.HOVERED;
  const dispatch = useDispatch();

  const [isClicked, setIsClicked] = useState(false);

  // On Hover Event Handlers
  function handleHoverPointerOn(e, vector3) {
    dispatch(showOrientationReducerInfo({ timestamp: e.timeStamp, vector: vector3 }));
    setIsClicked(!isClicked);
  }

  function handlePointerOff() {
    dispatch(resetReducerInfo())
    setIsClicked(!isClicked);
  }


  return (
    <React.Fragment key={vector3.index}>
      <mesh
        position={[vector3.x, vector3.y, vector3.z]}
        scale={config.ORIENTATIONMARKERS.SCALE}
        rotation={vector3.orientation}
        onPointerEnter={(e) => { handleHoverPointerOn(e, vector3) }}
        onPointerOut={() => { handlePointerOff() }}
      >
        <coneGeometry />
        <meshStandardMaterial color={isClicked ? hoveredColor : color} />
      </mesh>
    </React.Fragment>
  );
}

export default OrientationMarkerDraw;
