import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleDifficultAreasVisible, selectIsVisible, selectPercentOfSpeed, changePercentOfSpeed } from "../DifficultAreas/DifficultAreasReducer";


export const DifficultAreaControls = () => {

  // Get Number of markers
  const threshold = useSelector(selectPercentOfSpeed);

  // assign dispatch
  const dispatch = useDispatch();
  const isVisible = useSelector(selectIsVisible);

  return (
    <div id="DifficultAreaControls">
      <div className="IndividualControl">
        <Form.Check
          label={'Show Possible Areas of Difficulty'}
          checked={isVisible}
          onChange={() => { dispatch(toggleDifficultAreasVisible()) }}
        />
      </div>

      <div className="IndividualControl">
        <label>Difficult Areas Indicated By Slowest</label>
        <div className="ticks">
          <span className="tick"></span>
          <span className="tick"></span>
          <span className="tick"></span>
          <span className="tick"></span>
          <span className="tick"></span>
        </div>
        <Form.Range
          value={threshold}
          onChange={(e) => { dispatch(changePercentOfSpeed(e.target.value)) }}
          min={1}
          max={10}
        />
      </div>
    </div>
  );
}
