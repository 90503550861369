import React from "react"
import { PathControls } from "./PathControls";
import { InspectionControls } from "./InspectionControls"
import { OrientationControls } from "./OrientationControls";
import { DifficultAreaControls } from "./DifficultAreaControls";
import { RoomControls } from "./RoomControls";
import { CameraControls } from "./CameraControls"
import { StartAndEndControls } from "./StartAndEndControls";
import { AboutModal } from "./AboutModal";
import "./UserControls.css"


// Container for all control panel features
export const ControlPanel = () => {
  return (
    <>
      <PathControls />
      <InspectionControls />
      <StartAndEndControls />
      <OrientationControls />
      <DifficultAreaControls />
      <RoomControls />
      <CameraControls />
      <AboutModal />
    </>
  );
}