import { createSlice } from "@reduxjs/toolkit";

// State slice to manage the number of visible orientation markers
export const StartAndEndSlice = createSlice({
  name: "startandend",
  initialState: {
    StartPointVisible: true,
    EndPointVisible: true,
  },
  reducers: {
    toggleStartPointsVisible: (state) => {
      state.StartPointVisible = !state.StartPointVisible;
    },
    toggleEndPointsVisible: (state) => {
      state.EndPointVisible = !state.EndPointVisible;
    },
  },
});

export const { toggleStartPointsVisible, toggleEndPointsVisible } =
  StartAndEndSlice.actions;

export const selectIsStartVisible = (state) =>
  state.startandend.StartPointVisible;
export const selectIsEndVisible = (state) => state.startandend.EndPointVisible;
export default StartAndEndSlice.reducer;
