import * as THREE from "three";
import { DouglasPeucker } from "./PathCompression";
import config from "../config.json";

export default function loadPaths(pathData) {
  // Returns a JS object from JSON file (uses synchronous Http request)
  function RequestFlightPointData(dataFile) {
    // Request the data
    let request = new XMLHttpRequest();
    request.open("GET", process.env.PUBLIC_URL + dataFile, false);
    request.send(null);
    let flightObj = JSON.parse(request.responseText);

    let ratio = Math.floor(flightObj.length / config.MAX_POINTS);
    let points = [];

    // Limit points to 500
    for (let i = 0; points.length < config.MAX_POINTS; i += ratio) {
      let element = flightObj[i];
      let velX = 0;
      let velY = 0;
      let velZ = 0;

      if (i !== 0 && i !== config.MAX_POINTS) {
        let previousElement = flightObj[i - ratio];
        let nextElement = flightObj[i + ratio];
        let dT = parseFloat(nextElement.t) - parseFloat(previousElement.t);
        velX = (parseFloat(nextElement.x) - parseFloat(previousElement.x)) / dT;
        velY = (parseFloat(nextElement.y) - parseFloat(previousElement.y)) / dT;
        velZ = (parseFloat(nextElement.z) - parseFloat(previousElement.z)) / dT;
      }

      let s = Math.sqrt(velX ** 2 + velY ** 2 + velZ ** 2);

      let point = {
        x: parseFloat(element.x),
        y: parseFloat(element.y),
        z: parseFloat(element.z),
        time: parseFloat(element.t),
        pitch: parseFloat(element.pitch),
        roll: parseFloat(element.roll),
        yaw: parseFloat(element.yaw),
        vX: velX,
        vY: velY,
        vZ: velZ,
        speed: s,
      };

      points.push(point);
    }

    return points;
  }

  // Compresses and returns an array of path positions
  function CompressPath(pathObject) {
    let uncompressed = [];

    pathObject.forEach((element) => {
      uncompressed.push({
        x: element.x,
        y: element.y,
        z: element.z,
        pitch: element.pitch,
        yaw: element.yaw,
        roll: element.roll,
        speed: element.speed,
        time: element.time,
        vX: element.vX,
        vY: element.vY,
        vZ: element.vZ,
      });
    });

    return DouglasPeucker(uncompressed, config.EPSILON);
  }

  // Generates a curve
  function GeneratePathCurve(dataFile) {
    let dataFileObject = RequestFlightPointData(dataFile);
    //console.log("PRE-COMPRESSION: " + dataFileObject.length)
    let compressedPath = CompressPath(dataFileObject);
    //console.log("POST-COMPRESSION: " + compressedPath.length)

    let pathVectors = [];
    compressedPath.forEach((position) => {
      pathVectors.push(
        new THREE.Vector3(
          parseFloat(position.x),
          parseFloat(position.y),
          parseFloat(position.z)
        )
      );
    });

    let curve = new THREE.CatmullRomCurve3(pathVectors);

    let orientationValues = [];
    compressedPath.forEach((position) => {
      orientationValues.push({
        pitch: parseFloat(position.pitch),
        yaw: parseFloat(position.yaw),
        roll: parseFloat(position.roll),
        time: parseFloat(position.time),
        velX: parseFloat(position.vX),
        velY: parseFloat(position.vY),
        velZ: parseFloat(position.vZ),
      });
    });

    let difficultValues = [];
    compressedPath.forEach((position) => {
      difficultValues.push({
        x: parseFloat(position.x),
        y: parseFloat(position.y),
        z: parseFloat(position.z),
        speed: parseFloat(position.speed),
      });
    });

    return {
      curve: curve,
      orientationValues: orientationValues,
      difficultValues: difficultValues,
    };
  }

  return GeneratePathCurve(pathData);
}
