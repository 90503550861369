import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleStartPointsVisible, toggleEndPointsVisible, selectIsEndVisible, selectIsStartVisible } from "../PathPoints/PathPointReducer";

export const StartAndEndControls = () => {


  // assign dispatch
  const dispatch = useDispatch();
  const isStartVisible = useSelector(selectIsStartVisible);
  const isEndVisible = useSelector(selectIsEndVisible);

  return (
    <div id="StartAndEndControls">
      <div className="IndividualControl">
        <Form.Check
          label={'Toggle Path Start Markers'}
          checked={isStartVisible}
          onChange={() => { dispatch(toggleStartPointsVisible()) }}
        />
      </div>
      <div className="IndividualControl">
        <Form.Check
          label={'Toggle Path End Markers'}
          checked={isEndVisible}
          onChange={() => { dispatch(toggleEndPointsVisible()) }}
        />
      </div>
    </div>
  );
}
