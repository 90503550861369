import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import config from "../../config.json";

import { showInspectionReducerInfo, resetReducerInfo } from "../ToolTip/ToolTipReducer";

function InspectionMarkerDraw(props) {
  const vector3 = props.marker;
  const hoveredColor = config.COLORS.HOVERED;
  const dispatch = useDispatch();

  const [isClicked, setIsClicked] = useState(false);

  // On Hover Event Handlers
  function handleHoverPointerOn(e, vector3) {
    dispatch(showInspectionReducerInfo({ x: e.x, vector: vector3 }));
    setIsClicked(!isClicked);
  }

  function handlePointerOff() {
    dispatch(resetReducerInfo())
    setIsClicked(!isClicked);
  }


  return (
    <React.Fragment>
      <mesh position={[vector3.x, vector3.y, vector3.z]}
        scale={config.INSPECTIONMARKERS.SCALE}
        onPointerEnter={(e) => { handleHoverPointerOn(e, vector3) }}
        onPointerOut={() => { handlePointerOff() }}
      >
        <boxGeometry />
        <meshStandardMaterial color={isClicked ? hoveredColor : config.INSPECTIONMARKERS.COLOR} />
      </mesh>
    </React.Fragment>
  );
}

export default InspectionMarkerDraw;