import React from "react";
import { Table } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeVisible, selectVisiblePaths } from "../Path/PathReducer";



export const PathControls = () => {

  const dispatch = useDispatch();
  const visiblePaths = useSelector(selectVisiblePaths);


  return (
    <div id="PathControls">
      <header>Drone Flight Paths</header>
      <div className="IndividualControl">
        <label>Select Inspection Drone Flight Paths</label>
        <div id="table-div">
          <Table borderless size="sm">
            <tbody>
              <tr>
                <th>Run 1</th>
                <th>Run 2</th>
              </tr>
              <tr>
                <th><Form.Check checked={(visiblePaths[0].show)} onChange={() => { dispatch(changeVisible({ index: 0 })) }} /> </th>
                <th><Form.Check checked={(visiblePaths[1].show)} onChange={() => { dispatch(changeVisible({ index: 1 })) }} /> </th>
                <th>Pilot 1</th>
              </tr>
              <tr>
                <th><Form.Check checked={(visiblePaths[2].show)} onChange={() => { dispatch(changeVisible({ index: 2 })) }} /> </th>
                <th><Form.Check checked={(visiblePaths[3].show)} onChange={() => { dispatch(changeVisible({ index: 3 })) }} /> </th>
                <th>Pilot 2</th>
              </tr>

              <tr>
                <th><Form.Check checked={(visiblePaths[4].show)} onChange={() => { dispatch(changeVisible({ index: 4 })) }} /> </th>
                <th><Form.Check checked={(visiblePaths[5].show)} onChange={() => { dispatch(changeVisible({ index: 5 })) }} /> </th>
                <th>Pilot 3</th>
              </tr>
              <tr>
                <th><Form.Check checked={(visiblePaths[6].show)} onChange={() => { dispatch(changeVisible({ index: 6 })) }} /> </th>
                <th><Form.Check checked={(visiblePaths[7].show)} onChange={() => { dispatch(changeVisible({ index: 7 })) }} /> </th>
                <th>Pilot 4</th>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}


