import React, { useState } from "react";
import { Modal, Button, Row, Card, Col, Accordion } from "react-bootstrap";
import "./Help.css";

export const HelpModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="ButtonWrapper">
        <Button
          variant=""
          onClick={handleShow}
          className="ButtonElement"
        >
          ?
        </Button>
      </div>

      <Modal show={show} onHide={handleClose} size="xl" className="ModalWrapper">
        <Modal.Header closeButton>
          <Modal.Title>Help Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="RowElement">
            <Col>
              <Card className="CardElementAccordian">
                <Card.Body>
                  <Card.Subtitle className="CardTitle">Control Panel Guide:</Card.Subtitle>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Drone Flight Paths</Accordion.Header>
                      <Accordion.Body className="ModalText">
                        This section deals with all the flight paths and what they do. In 
                        here you will find the toggle to turn on and off all eight flight 
                        paths individually. You will also find the toggle to turn on and off 
                        all inspection points simultaneously. Lastly, this section also 
                        contains the toggles to turn on and off the start and end markers.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Drone Markers</Accordion.Header>
                      <Accordion.Body className="ModalText">
                        This section deals with all the markers found on the drone flight 
                        paths. In here you will find the toggle for turning on and off all 
                        of the drone flight orientation markers. You will also find a slider to 
                        increase or decrease the amount of the drone flight orientation markers 
                        per path. In this section you will also find a toggle for turning on and 
                        off all of the drone flight difficult area markers. These marker denote 
                        areas where the drone slowed down. You will also find a slider to 
                        increase or decrease the amount of the drone flight difficult area markers 
                        per path.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Model Display</Accordion.Header>
                      <Accordion.Body className="ModalText">
                        This section deals with the model and the different ways you can display it.
                        In here you will find three buttons that toggle between two different room 
                        displays, and the third which is no room display. The room model option shows 
                        the full room model, the wireframe room model option shows a wireframe/outline 
                        of the original room model, and the hide models option shows no room models at all.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Camera Toggle</Accordion.Header>
                      <Accordion.Body className="ModalText">
                        This section deals with the camera and how it displays the scene. In here you 
                        will find a toggle that switches between a perspective and orthographic view. 
                        The perspective view shows a camera view similiar to how you view the world. 
                        The orthographic view shows a camera view similiar to an isometeric view.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Row className="RowElementInnerTop">
                <Col>
                  <Card className="CardElement">
                    <Card.Body>
                      <Card.Subtitle className="CardTitle">Navigation Controls:</Card.Subtitle>
                      <li>Hold and Drag the left mouse button to rotate the model.</li>
                      <li>Hold and Drag the right mouse button to pan or move the model.</li>
                      <li>Use the mouse scroll wheel to zoom in and out of the model.</li>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row className="RowElementInnerBottom">
                <Col>
                  <Card className="CardElement">
                    <Card.Body>
                      <Card.Subtitle className="CardTitle">Known Issues:</Card.Subtitle>
                      <p className="ModalText">
                        After hovering over a point to inspect it, sometimes the point stays 
                        blackened out. This is an issue that has only been seen so far on safari. 
                        To remedy this issue, simply refresh the page.
                      </p>
                      <p> </p>
                      <p className="ModalText">
                        If the page appears laggy or slightly unresponsive consider switching 
                        the room model to the wireframe model. This is an issue that has only been 
                        seen on lower spec computers. To remedy this issue, choose the wireframe 
                        model over the original room model. The wireframe model is a smaller file so 
                        it's easier to render.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>     
        </Modal.Body>
      </Modal>
    </>
  );
};